<div [class.desktop]="(appService.isMobile$ | async) === false" class="menu-container" data-cy="menu-container">
  @if ((appService.isMobile$ | async) === false) {
    <div class="header">
      <ion-menu-toggle data-cy="desktop-in-menu-toggle-btn">
        <img src="assets/icon/white-close.svg" alt="" />
      </ion-menu-toggle>
      <div>{{ 'PWA_Menu_header' | translate }}</div>
    </div>
  }

  <form (ngSubmit)="searchBook()" [class.mobile]="appService.isMobile$ | async" [formGroup]="searchForm" class="search-container">
    <ion-input [placeholder]="'PWA_mobile_mainHeader_searchField' | translate" class="search" data-cy="menu-search-input" formControlName="search">
      <img slot="start" src="assets/icon/search.svg" />
    </ion-input>

    <app-button
      (clicked)="searchBook()"
      [arabicTransformIcon]="true"
      [icon]="'assets/icon/right-arrow-bold.svg'"
      [shape]="'circle'"
      [size]="'small'"
      data-cy="menu-search-btn" />
  </form>

  <div class="options">
    <div (click)="goToMyLibrary()" class="option" data-cy="menu-go-to-my-library-btn">
      {{ 'PWA_mobile_footer_item2_myLibrary' | translate }}
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>
    <div (click)="goToLanguage()" class="option" data-cy="menu-go-to-language">
      {{ 'PWA_Menu_link_appLanguage' | translate }}
      <app-button
        [color]="'blue'"
        [icon]="'assets/icon/globe.svg'"
        [label]="(selectedLanguageName$ | async) || ''"
        [size]="'xxsmall'"
        data-cy="menu-go-to-language-label" />
    </div>
    <div (click)="goToLibraryCode()" class="option">
      <div>{{ 'PWA_Menu_link_libraryCode' | translate }}</div>
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>
    <div (click)="notImplemented()" class="option">
      {{ 'PWA_Menu_link_notifications' | translate }}
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>
    <div (click)="howToUse()" class="option">
      {{ 'PWA_Menu_link_howToUseBooksmart' | translate }}
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>
    <div (click)="goToHelp()" class="option">
      {{ 'PWA_Menu_link_getHelp' | translate }}
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>
    <div (click)="openTerms()" class="option">
      {{ 'PWA_Menu_link_TandC' | translate }}
      <img alt="" class="arabic-transform" src="assets/icon/big-arrow-right.svg" />
    </div>

    @if ((userState.isMoya$ | async) === false) {
      @if (userState.isGuest$ | async) {
        <div class="option" (click)="login()">
          {{ 'PWA_LoginOrCreateAccount_header' | translate }}
          <img src="assets/icon/big-arrow-right.svg" alt="" class="arabic-transform" />
        </div>
      } @else {
        <div class="option" (click)="logout()" data-cy="logout-btn">
          {{ 'PWA_Menu_link_logout' | translate }}
          <img src="assets/icon/logout.svg" alt="" class="arabic-transform" />
        </div>
      }
    }
  </div>

  @if ((userState.isGuest$ | async) === false && (userState.isMoya$ | async) === false) {
    <div class="delete-account" data-cy="delete-account-btn" (click)="deleteAccount()">{{ 'PWA_Menu_link_deleteAccount' | translate }}</div>
  }

  <div [class.mobile]="appService.isMobile$ | async" class="powered-by">
    <img alt="" src="assets/logo/wr-powered-by.svg" />
  </div>

  <div [class.mobile]="appService.isMobile$ | async" class="app-version">
    {{ 'PWA_Menu_info_appVersion' | translate: { versionNumber } }}
    @if (env !== 'prod') {
      - {{ env }}
    }
  </div>
</div>
