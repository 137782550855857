import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '../../services/authentication/user.service';
import { AnalyticsEventType, ModalViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { ButtonComponent } from '../../components/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from '../../services/modal.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-logout-confirmation-modal',
  templateUrl: './logout-confirmation-modal.page.html',
  styleUrls: ['./logout-confirmation-modal.page.scss'],
  imports: [ButtonComponent, TranslateModule],
})
export class LogoutConfirmationModalPage {
  constructor(
    private modalService: ModalService,
    private userService: UserService,
    private analyticsService: AnalyticsService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: ModalViewEvents.LogoutConfirmationModal });
  }

  closeModal(): void {
    void this.modalService.dismiss();
  }

  logout(): void {
    void this.userService.logout();
    void this.modalService.dismiss();
  }
}
