import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonInput, IonMenuToggle, MenuController, Platform } from '@ionic/angular/standalone';
import { UserState } from '../../store/user.state';
import { map } from 'rxjs';
import { LanguageState } from '../../store/language.state';
import { environment } from '../../../environments/environment';
import { ToastService, ToastType } from '../../services/toast.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserService } from '../../services/browser.service';
import { version } from './../../../../package.json';
import { AppService } from '../../services/app.service';
import { DeleteAccountModalPage } from '../../modals/delete-account/delete-account-modal.page';
import { LogoutConfirmationModalPage } from '../../modals/logout-confirmation/logout-confirmation-modal.page';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AnalyticsEventType, PageViewEvents } from '../../services/analytics/analytics.model';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { runtimeEnvironment } from '../../../environments/environment.r';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '../button/button.component';
import { NavigationService } from '../../services/navigation.service';
import { ComponentRef } from '@ionic/core';
import { ModalService } from '../../services/modal.service';
import { ParentalGuardService } from '../../services/parental-guard.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  imports: [AsyncPipe, IonMenuToggle, IonInput, ReactiveFormsModule, TranslateModule, ButtonComponent],
})
export class MenuComponent {
  readonly env = environment.envName;
  readonly versionNumber = `v${version} (${runtimeEnvironment.BUILD_NUMBER})`;
  selectedLanguageName$ = this.languageState.language$.pipe(
    map(language => language.availableLanguages.find(availableLanguages => availableLanguages.code === language.selected)?.name),
  );
  searchForm = new FormGroup({
    search: new FormControl('', [Validators.required]),
  });
  protected readonly Platform = Platform;

  constructor(
    public appService: AppService,
    public userState: UserState,
    private modalService: ModalService,
    private navigationService: NavigationService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private browserService: BrowserService,
    private menuController: MenuController,
    private analyticsService: AnalyticsService,
    private languageState: LanguageState,
    private parentalGuardService: ParentalGuardService,
  ) {}

  ionViewDidEnter(): void {
    this.analyticsService.sendEvent(AnalyticsEventType.InScreen, { screenName: PageViewEvents.Menu });
  }

  searchBook(): void {
    const query = this.searchForm.get('search')?.value;
    this.searchForm.reset();

    void this.navigationService.navigate('/home', { queryParams: { search: query } });
    void this.closeMenuIfDesktop();
  }

  async login(): Promise<void> {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('authentication');
  }

  async logout(): Promise<void> {
    void this.closeMenuIfDesktop();

    const logoutConfirmationModal = await this.modalService.create({
      component: LogoutConfirmationModalPage as ComponentRef,
      cssClass: 'wr-modal logout-confirmation not-fullscreen',
    });

    await logoutConfirmationModal.present();
  }

  async goToHelp(): Promise<void> {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('help');
  }

  howToUse(): void {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('how-to-use');
  }

  async openTerms(): Promise<void> {
    void this.closeMenuIfDesktop();
    if (await this.parentalGuardService.parentalGuardCompleted()) {
      this.browserService.goTo(this.translateService.instant('PWA_createAccount_check_TC_normal_URL'));
    }
  }

  goToLibraryCode(): void {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('library-code');
  }

  goToMyLibrary(): void {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('my-library');
  }

  goToLanguage(): void {
    void this.closeMenuIfDesktop();
    void this.navigationService.navigate('language');
  }

  notImplemented(): void {
    void this.toastService.present({
      message: 'Feature still under development',
      type: ToastType.Informative,
      displayClose: false,
    });
  }

  async closeMenuIfDesktop(): Promise<void> {
    if (!this.appService.isMobile$.value) {
      void this.menuController.close();
    }
  }

  async deleteAccount(): Promise<void> {
    void this.closeMenuIfDesktop();
    const deleteAccountModal = await this.modalService.create({
      component: DeleteAccountModalPage as ComponentRef,
      cssClass: 'wr-modal delete-account',
    });

    await deleteAccountModal.present();
  }
}
